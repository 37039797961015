.react-calendar {
  width: 230px;
  max-width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px -6px #252525;
  border-radius: 15px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  padding: 5px;
}

.react-calendar--doubleView {
  width: 500px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
  border-radius: 15px;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  height: 36px;
  margin-bottom: 0.5em !important;
  display: flex;
}

.react-calendar__navigation button {
  width: 33px;
  height: 33px;
  background: none;
  font-size: 0.5em !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #F0F0F0;
}

.react-calendar__navigation button[disabled] {
  background-color: #F0F0F0;
}

button.react-calendar__navigation__label {
  display: flex;
  justify-content: center;
  align-items: center;
}

button.react-calendar__navigation__label span {
  font-size: 12px !important;
}

button.react-calendar__navigation__arrow {
  font-size: 20px !important;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: capitalize !important;
  font-weight: bold;
  font-size: 0.7em !important;
  margin-bottom: 0.3em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em 0em;
}

.react-calendar__month-view__weekdays__weekday abbr {
  font-size: 12px !important;
  text-decoration: none !important;
}

.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.6em !important;
  padding: calc(0.6em / 0.6) calc(0.5em / 0.6);
}

.react-calendar__month-view__days {
  display: grid !important;
  grid-template-columns: repeat(7, 1fr);
  gap: 2px;
}

.react-calendar__year-view__months,
.react-calendar__decade-view__years,
.react-calendar__century-view__decades {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
  gap: 2px;
}

.react-calendar__month-view__days__day--weekend {
  color: #000000 !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #DDDDDD !important;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 10px 0.5em 8px;
  width: 100%;
}

button.react-calendar__month-view__days__day {
  padding-top: 3px !important;
}

.react-calendar__tile {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background: none;
  border-radius: 15px;
  font-size: 0.8em !important;
}

.react-calendar__tile:disabled {
  background-color: #F0F0F0 !important;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #F0F0F0 !important;
  color: #000000 !important;
}

.react-calendar__tile--now {
  background-color: #FFC100 !important;
  color: #FFFFFF !important;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #FFC100 !important;
  color: #FFFFFF !important;
}

.react-calendar__tile--hasActive {
  background: #76BAFF;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #A9D4FF;
}

.react-calendar__tile--active {
  background: #252525 !important;
  color: #FFFFFF !important;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background-color: #252525 !important;
  color: #FFFFFF !important;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #F0F0F0 !important;
}
